/* Base Styles */
body {
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #000000;
  font-family: 'Cairo', sans-serif;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  top: 0;
  z-index: 1000;
}



.logo {
  display: flex;
  align-items: center;
  color: #00b3d3;
}

/* Hide close button by default */
.close-btn {
  display: none;
}

/* Show close button only when menu is open */
.sidebar.show .close-btn {
  display: block;
}

.logo-img {
  width: 100px;
  height: auto;
  margin-right: 5px;
  margin-left: 5px;

}

.logo-text {
  font-size: 2.4em;
  font-weight: bold;
  color: #00b3d3;
  font-family: 'Cairo', sans-serif;
  background: none;
  border: none;

}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 0;
  padding: 8px 10px;
}

nav ul li {
  display: inline;
  
}

nav ul li a,
nav ul li button {
  font-family: 'Cairo', sans-serif;
  text-decoration: none;
  color: #000000;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.4em;
  font-weight: bold;
  padding: 0px 12px;
  transition: background-color 0.3s;
}

nav ul li button:hover,
nav ul li a:hover {
  background-color: #00b3d3;
  color: #fff;
  border-radius: 10px;
}




.hero-section {
  justify-content: space-between;

  text-align: center;
  padding: 80px 20px;
  background: linear-gradient(to bottom, #fff, #fff);
}



.hero-section h1 {
  margin-top: 40px;
  font-size: 3.6em;
  margin-bottom: 40px;
  font-weight: 700;
  font-family: 'Cairo', sans-serif;
}

.hero-section p {
  font-size: 1.6em;
  margin-bottom: 90px;
  color: #666;
  font-family: 'Cairo', sans-serif;
}

.app-buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 90px;
  margin-top: 30;

}

.app-buttons a img {
  width: 300px;
  transition: transform 0.2s;
}

.app-buttons a img:hover {
  transform: scale(1.05);
}

.phone-image {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.phone-frame {
  width: 320px;
  height: auto;
  transition: transform 0.3s;
  text-align: center;

}

.phone-frame img {
  width: 100%;
  height: auto;

}

.phone-frame:hover {
  transform: scale(1.05);
}

.image-text {
  padding: 10px 0;



}

.image-text h3 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 10px 0 5px;
  font-family: 'Roboto', sans-serif;
}

.image-text p {
  font-size: 1em;
  
  color: #555;
  font-family: 'Roboto', sans-serif;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

/* New Feature Sections */
.feature-section {
  display: flex;
  justify-content: space-between;
  align-items: safe;
  padding: 40px 80px;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
}

.feature-section:nth-child(even) {
  background-color: #f9f9f9;
}

.feature-image img {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.feature-details {
  max-width: 600px;
  text-align: left;
  padding: 0 20px;
}

.feature-details h3 {
  font-size: 2em;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: right;

  font-family: 'Montserrat', sans-serif;
}

.feature-details p {
  font-size: 1.2em;
  color: #666;
  font-family: 'Roboto', sans-serif;
  text-align: right;

}


/* Responsive Design */
@media (max-width: 768px) {
/* Hide close button by default */
.close-btn {
  display: none;
}

/* Show close button only when menu is open */
.sidebar.show .close-btn {
  display: block;
}


  .hero-section {
    padding: 20px 20px;
  }

  .hero-section h1 {
    font-size: 2em;
  }

  .hero-section p {
    font-size: 1em;
  }

  .phone-frame {
    width: 400px;
  }

  .phone-image {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  .feature-section {
    flex-direction: column;
    text-align: start;
  }

  .feature-image, .feature-details {
    width: 100%;
  }



}


@media (max-width: 576px) {



  .logo-img {
    width: 80px;
    height: auto;
   
  
  }


  .feature-section {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: safe;
    padding: 20px   5px;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
  }

  .phone-image {
    display: none;
   
  }

  .feature-image, .feature-details {
    width: 100%;
  }

  .feature-image img {
    width: 90%;
    max-width: 200px;
    height: auto;
  }

  .app-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 20;
  
  }

  header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px;
  }

  .menu-toggle {
    display: flex;
    color: #000000;
  }

  .logo {
    order: 1;
  }

  .menu-toggle {
    order: 1;
    margin-right: auto;
    flex-direction: column;


    padding: 10px;
    color: #000000;

  }

  .logo-text {
    font-size: 1.8em;
  }

  nav {
    display: none;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    position: absolute;
    top: 60px;
    right: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
  }

  nav.show {
    display: flex;
  }

  nav ul {
    flex-direction: column;
    gap: 15px;
    margin: 0;
    padding: 10px;

  }

  .hero-section {
    padding: 10px 10px;
  }

  .hero-section h1 {
    font-size: 1.5em;
  }

  .hero-section p {
    font-size: 0.9em;
  }

  .app-buttons a img {
    width: 200px;
  }

  .phone-frame {
    width: 300px;
  }


  /* Sidebar menu styles */
.sidebar {
  display: none;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0 5px 5px 0;
  padding: 20px;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
}

.sidebar.show {
  display: flex;
  transform: translateX(0);
}

.sidebar ul {
  flex-direction: column;
  gap: 50px;
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Close button styles */
.close-btn {
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  align-self: flex-end;
}

/* Toggle button styles */
.menu-toggle {
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color:#000000;
}



}

/* RTL Styles */
html[lang='ar'] {
  direction: rtl;
  font-family: 'Cairo', sans-serif;
}

html[lang='ar'] header {
  flex-direction: row-reverse;
  text-align: right;
}

html[lang='ar'] .hero-section {
  text-align: center;

}

html[lang='ar'] .app-buttons {
  flex-direction: row-reverse;
}

html[lang='ar'] .feature-section {
  flex-direction: row-reverse;
}

html[lang='ar'] .feature-details {
  text-align: right;
}

/* Additional Modern Styles */
body {
  font-family: 'Cairo', sans-serif;
}

header {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
  padding: 15px 15px;
}

.logo-text {
  color: #00b3d3;
}

nav ul li a,
nav ul li button {
  color: #333;
}

.hero-section {
  padding: 40px 20px;
  background: white;
}

.hero-section h1 {
  color: #333;
}

.hero-section p {
  color: #555;
}


/* Sidebar positioning */
.sidebar-right {
  right: 0;
  left: auto;
  transform: translateX(0);
}

.sidebar-left {
  left: 0;
  right: auto;
  transform: translateX(-100%);
}

/* Transition for sidebar */
.sidebar {
  transition: transform 0.3s ease-in-out;
}

/* Logo and menu toggle positioning */
.logo-left {
  order: 1;
}

.logo-right {
  order: 2;
}

.menu-toggle-left {
  order: 2;
}

.menu-toggle-right {
  order: 1;
}

/* RTL Adjustments */
html[lang='ar'] .sidebar-left {
  left: auto;
  right: 0;
}
html[lang='ar'] .sidebar-right {
  left: 0;
  right: auto;
}

html[lang='ar'] .logo-left {
  order: 2;
}

html[lang='ar'] .logo-right {
  order: 1;
}

html[lang='ar'] .menu-toggle-left {
  order: 1;
}

html[lang='ar'] .menu-toggle-right {
  order: 2;
}

body {
  font-family: 'Roboto', sans-serif; /* Default font for English */
}

body[dir="rtl"] {
  font-family: 'Cairo', sans-serif; /* Font for Arabic */
}

/* Additional styles for direction */
[dir="rtl"] {
  text-align: right;
}

[dir="ltr"] {
  text-align: left;
}




